import awsMobile from './aws-exports';

const domain = new URL(window.location.href).origin;

const config = {
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  oauth: {
    redirectSignIn: domain,
    redirectSignOut: `${domain}/sign-in`,
  },
};

const awsconfig = {
  ...awsMobile,
  ...config,
  mandatorySignIn: true,
  oauth: {
    ...awsMobile.oauth,
    ...config.oauth,
    responseType: 'code',
    scope: ['openid', 'aws.cognito.signin.user.admin'],
  },
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export default awsconfig;
