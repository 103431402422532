import type { Plan } from '@types';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export interface UserStorageSubscriptionDetails {
  stripeSubscriptionId: string | null;
  name: string | null;
  totalMinutes: number | null;
  updatedAt: string | null;
  nextBillingDay: number | null;
  lastFour: string | null;
  remainingMinutes: number | null;
  price: number | null;
  status: 'active' | 'canceled' | 'past_due' | null;
}

interface SubscriptionSliceProps {
  subscriptionInfo: UserStorageSubscriptionDetails | null;
  products: Plan[] | null;
  cancelSubscriptionId: string | null;
  additionalMinutesPaymentIntentId: string | null;
}
const initialState: SubscriptionSliceProps = {
  subscriptionInfo: null,
  products: null,
  cancelSubscriptionId: null,
  additionalMinutesPaymentIntentId: null,
};

export const SubscriptionAdapter =
  createEntityAdapter<SubscriptionSliceProps>();

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: SubscriptionAdapter.getInitialState(initialState),
  reducers: {
    initSubscriptionInfo: (
      state,
      action: PayloadAction<UserStorageSubscriptionDetails | null>,
    ) => {
      state.subscriptionInfo = action.payload;
    },
    setCancelSubscriptionId: (state, action: PayloadAction<string | null>) => {
      state.cancelSubscriptionId = action.payload;
    },
    setProducts: (state, action: PayloadAction<Plan[] | null>) => {
      state.products = action.payload;
    },
    setAdditionalMinutesPaymentIntentId: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.additionalMinutesPaymentIntentId = action.payload;
    },
  },
});

export default subscriptionsSlice.reducer;
export const {
  initSubscriptionInfo,
  setCancelSubscriptionId,
  setProducts,
  setAdditionalMinutesPaymentIntentId,
} = subscriptionsSlice.actions;
