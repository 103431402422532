import React from 'react';

export const Plus = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 8.66666H8.66663V13.3333H7.33329V8.66666H2.66663V7.33332H7.33329V2.66666H8.66663V7.33332H13.3333V8.66666Z"
        fill="currentColor"
      />
    </svg>
  );
};
