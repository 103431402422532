import React from 'react';

export const Eye = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#0370F3" />
      <path
        d="M16.0001 10.9583C19.4742 10.9583 22.5726 12.9108 24.0851 16C22.5726 19.0892 19.4834 21.0417 16.0001 21.0417C12.5167 21.0417 9.42758 19.0892 7.91508 16C9.42758 12.9108 12.5259 10.9583 16.0001 10.9583ZM16.0001 9.125C11.4167 9.125 7.50258 11.9758 5.91675 16C7.50258 20.0242 11.4167 22.875 16.0001 22.875C20.5834 22.875 24.4976 20.0242 26.0834 16C24.4976 11.9758 20.5834 9.125 16.0001 9.125ZM16.0001 13.7083C17.2651 13.7083 18.2917 14.735 18.2917 16C18.2917 17.265 17.2651 18.2917 16.0001 18.2917C14.7351 18.2917 13.7084 17.265 13.7084 16C13.7084 14.735 14.7351 13.7083 16.0001 13.7083ZM16.0001 11.875C13.7267 11.875 11.8751 13.7267 11.8751 16C11.8751 18.2733 13.7267 20.125 16.0001 20.125C18.2734 20.125 20.1251 18.2733 20.1251 16C20.1251 13.7267 18.2734 11.875 16.0001 11.875Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};
