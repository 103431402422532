import React from 'react';

export const CircledDollar = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_585_21745)">
        <path
          d="M8.1834 11.5548C7.6026 11.5548 7.0526 11.4624 6.5334 11.2776C6.0142 11.084 5.5698 10.798 5.2002 10.4196L5.7414 9.44279C6.1374 9.79479 6.5334 10.05 6.9294 10.2084C7.3342 10.3668 7.7346 10.446 8.1306 10.446C8.5354 10.446 8.8654 10.3712 9.1206 10.2216C9.3758 10.0632 9.5034 9.83439 9.5034 9.53519C9.5034 9.31519 9.4374 9.15239 9.3054 9.04679C9.1734 8.94119 8.9798 8.84879 8.7246 8.76959C8.4782 8.69039 8.179 8.60239 7.827 8.50559C7.3342 8.35599 6.9206 8.20199 6.5862 8.04359C6.2518 7.87639 6.001 7.67399 5.8338 7.43639C5.6666 7.19879 5.583 6.89519 5.583 6.52559C5.583 6.07679 5.6974 5.69399 5.9262 5.37719C6.155 5.06039 6.4718 4.81839 6.8766 4.65119C7.2902 4.48399 7.7654 4.40039 8.3022 4.40039C8.8038 4.40039 9.2658 4.47959 9.6882 4.63799C10.1194 4.78759 10.4978 5.02519 10.8234 5.35079L10.203 6.31439C9.895 6.03279 9.5782 5.82599 9.2526 5.69399C8.9358 5.56199 8.5926 5.49599 8.223 5.49599C8.0118 5.49599 7.8094 5.52239 7.6158 5.57519C7.4222 5.61919 7.2594 5.70719 7.1274 5.83919C7.0042 5.96239 6.9426 6.13839 6.9426 6.36719C6.9426 6.55199 6.9954 6.70159 7.101 6.81599C7.2066 6.93039 7.365 7.02719 7.5762 7.10639C7.7874 7.18559 8.047 7.27359 8.355 7.37039C8.8918 7.52879 9.3494 7.68719 9.7278 7.84559C10.1062 7.99519 10.3966 8.19319 10.599 8.43959C10.8102 8.67719 10.9158 9.00719 10.9158 9.42959C10.9158 10.0984 10.665 10.622 10.1634 11.0004C9.6706 11.37 9.0106 11.5548 8.1834 11.5548Z"
          fill="#0370F3"
        />
        <line
          x1="8.10059"
          y1="3.2002"
          x2="8.10058"
          y2="12.8002"
          stroke="#0370F3"
        />
        <rect
          x="0.6"
          y="0.6"
          width="14.8"
          height="14.8"
          rx="7.4"
          stroke="#0370F3"
          strokeWidth="1.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_585_21745">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
