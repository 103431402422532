import React from 'react';

import './assets/index.css';
import reportWebVitals from './reportWebVitals';

import { router } from '@routes/routes';

import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<RouterProvider router={router} />);

reportWebVitals();
