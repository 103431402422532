import { baseQuery } from '@store/utils';

import type { Meeting, MeetingType } from '@types';

import { createApi } from '@reduxjs/toolkit/query/react';

export interface MeetingsResponse<Type> {
  data: Type;
  meta: {
    count: number;
  };
}

interface GetMeetingsPayload {
  code: string | null;
}
interface Transcription {
  transcription: string;
}

export type UpdatedMeeting = Omit<
  Meeting,
  'isActive' | 'id' | 'createdAt' | 'updatedAt'
>;
interface UpdateMeetingPayload {
  meeting: UpdatedMeeting;
  id: number;
}
interface CreateTestMeetingPayload {
  email: string;
  type: MeetingType;
}
interface CreateTestMeetingResponse {
  link: string;
}
export const meetingsApi = createApi({
  reducerPath: 'meetings',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getMeetingsList: builder.mutation<
      MeetingsResponse<Meeting[]>,
      GetMeetingsPayload
    >({
      query: (payload) => ({
        url: 'meeting/list',
        method: 'POST',
        body: {
          ...payload,
        },
      }),
    }),
    updateMeeting: builder.mutation<
      MeetingsResponse<Meeting>,
      UpdateMeetingPayload
    >({
      query: ({ id, meeting }) => ({
        url: `meeting/${id}`,
        method: 'PUT',
        body: { ...meeting },
      }),
    }),
    getTranscription: builder.query<MeetingsResponse<Transcription>, number>({
      query: (id) => ({
        url: `meeting/transcription/${id}`,
      }),
    }),
    createMeetingByPastedLink: builder.mutation<
      MeetingsResponse<Meeting>,
      string
    >({
      query: (link) => ({
        url: 'meeting/creating-by-pasted-link',
        method: 'POST',
        body: { link },
      }),
    }),
    createTestMeeting: builder.mutation<
      MeetingsResponse<CreateTestMeetingResponse>,
      CreateTestMeetingPayload
    >({
      query: (payload) => ({
        url: 'meeting/creating-test-meeting',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetMeetingsListMutation,
  useUpdateMeetingMutation,
  useGetTranscriptionQuery,
  useCreateMeetingByPastedLinkMutation,
  useCreateTestMeetingMutation,
} = meetingsApi;
