import React from 'react';

export const ArrowExpand = () => {
  return (
    <svg
      width="100%"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.77 0.229981L2.1107e-08 1.99998L10 12L20 1.99998L18.23 0.22998L10 8.45998L1.77 0.229981Z"
        fill="currentColor"
      />
    </svg>
  );
};
