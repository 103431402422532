import React from 'react';
import type { FC } from 'react';

interface HamburgerIconProps {
  className?: string;
}

export const HamburgerIcon: FC<HamburgerIconProps> = () => {
  return (
    <svg
      width="45"
      height="40"
      viewBox="0 0 45 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="10" y1="12.5" x2="36" y2="12.5" stroke="#454F5B" />
      <line x1="10" y1="19.5" x2="33" y2="19.5" stroke="#454F5B" />
      <line x1="10" y1="26.5" x2="30" y2="26.5" stroke="#454F5B" />
    </svg>
  );
};
