import React from 'react';

export const RemoveItem = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="22" height="22" rx="11" fill="#FEFEFE" />
      <path
        d="M6 12H18"
        stroke="#0370F3"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        rx="11"
        stroke="#0370F3"
        strokeWidth="2"
      />
    </svg>
  );
};
