import React from 'react';

export const BackArrow = () => {
  return (
    <svg
      width="22"
      height="12"
      viewBox="0 0 22 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.65366 7.33301L21.667 7.33301L21.667 4.66634L5.65366 4.66634L5.65366 0.666342L0.33366 5.99968L5.65366 11.333L5.65366 7.33301Z"
        fill="currentColor"
      />
    </svg>
  );
};
