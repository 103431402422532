import React from 'react';

export const Calendar = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#0370F3" />
      <g clipPath="url(#clip0_136_5622)">
        <path
          d="M18.9131 16.062L15.068 19.9071L13.0869 17.926C12.8219 17.661 12.3925 17.661 12.1274 17.926C11.8623 18.1911 11.8623 18.6205 12.1274 18.8856L14.5882 21.3464C14.7207 21.4789 14.8944 21.5452 15.068 21.5452C15.2416 21.5452 15.4152 21.4789 15.5477 21.3464L19.8726 17.0215C20.1377 16.7564 20.1377 16.327 19.8726 16.062C19.6076 15.7969 19.1782 15.7969 18.9131 16.062Z"
          fill="#F9FAFB"
        />
        <path
          d="M22.1071 7.82997H21.0893V7.17857C21.0893 6.80383 20.7855 6.5 20.4107 6.5C20.036 6.5 19.7321 6.80383 19.7321 7.17857V7.82997H12.2679V7.17857C12.2679 6.80383 11.964 6.5 11.5893 6.5C11.2145 6.5 10.9107 6.80383 10.9107 7.17857V7.82997H9.89286C8.02215 7.82997 6.5 9.35212 6.5 11.2228V22.1071C6.5 23.9779 8.02215 25.5 9.89286 25.5H22.1071C23.9779 25.5 25.5 23.9779 25.5 22.1071V11.2228C25.5 9.35212 23.9779 7.82997 22.1071 7.82997ZM7.85714 11.2228C7.85714 10.1003 8.7703 9.18712 9.89286 9.18712H10.9107V9.83819C10.9107 10.2129 11.2145 10.5168 11.5893 10.5168C11.964 10.5168 12.2679 10.2129 12.2679 9.83819V9.18712H19.7321V9.83819C19.7321 10.2129 20.036 10.5168 20.4107 10.5168C20.7855 10.5168 21.0893 10.2129 21.0893 9.83819V9.18712H22.1071C23.2297 9.18712 24.1429 10.1003 24.1429 11.2228V11.908H7.85714V11.2228ZM22.1071 24.1429H9.89286C8.7703 24.1429 7.85714 23.2297 7.85714 22.1071V13.2652H24.1429V22.1071C24.1429 23.2297 23.2297 24.1429 22.1071 24.1429Z"
          fill="#F9FAFB"
        />
      </g>
      <defs>
        <clipPath id="clip0_136_5622">
          <rect
            width="19"
            height="19"
            fill="white"
            transform="translate(6.5 6.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
