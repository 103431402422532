import { adminApi } from '@store/admin';
import adminSlice from '@store/admin/admin.slice';
import authSlice from '@store/auth/auth.slice';
import { botApi } from '@store/bot';
import { calendarApi } from '@store/calendar';
import { meetingsApi } from '@store/meetings';
import meetingsSlice from '@store/meetings/meetings.slice';
import { preModerationApi } from '@store/preModeration/preModeration.api';
import preModerationSlice from '@store/preModeration/preModeration.slice';
import { subscriptionsApi } from '@store/subscription';
import subscriptionsSlice from '@store/subscription/subscriptions.slice';
import { userApi } from '@store/user';

import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  [meetingsApi.reducerPath]: meetingsApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [botApi.reducerPath]: botApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
  [calendarApi.reducerPath]: calendarApi.reducer,
  [preModerationApi.reducerPath]: preModerationApi.reducer,
  adminSlice,
  meetingsSlice,
  authSlice,
  subscriptionsSlice,
  preModerationSlice,
});

export default rootReducer;
