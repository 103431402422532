import React from 'react';

export const Zoom = ({ className }: { className?: string }) => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <rect x="1" y="0.5" width="21" height="21" rx="10.5" fill="#3E80F6" />
      <rect x="1" y="0.5" width="21" height="21" rx="10.5" stroke="white" />
      <path
        d="M6.66667 7.25C6.22944 7.25 5.875 7.64975 5.875 8.14288V12.4732C5.875 13.7306 6.77882 14.75 7.89375 14.75L12.5833 14.7054C13.0206 14.7054 13.375 14.3056 13.375 13.8125V9.4375C13.375 8.18006 12.3024 7.25 11.1875 7.25H6.66667Z"
        fill="white"
      />
      <path
        d="M14.4451 8.95475C14.1623 9.2345 14 9.63819 14 10.0625V11.8746C14 12.2989 14.1623 12.7026 14.4451 12.9823L16.2055 14.5328C16.5637 14.8869 17.125 14.6001 17.125 14.0628V7.9595C17.125 7.42219 16.5637 7.13534 16.2055 7.4895L14.4451 8.95475Z"
        fill="white"
      />
    </svg>
  );
};
