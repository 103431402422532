import React from 'react';

export const CheckSign = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="shadow-card rounded-full min-h-[24px] min-w-[24px]"
    >
      <path
        d="M11.9999 0C-3.99805 0.502029 -4.00186 23.4956 11.9999 24C27.9979 23.498 28.0017 0.504343 11.9999 0Z"
        fill="#FEFEFE"
      />
      <path
        d="M17.7743 9.17292L10.5125 15.7996C10.443 15.8632 10.3604 15.9135 10.2695 15.9479C10.1786 15.9823 10.0812 16 9.9828 16C9.88442 16 9.78699 15.9823 9.6961 15.9479C9.60522 15.9135 9.52264 15.8632 9.4531 15.7996L6.22576 12.8542C5.52831 12.2365 6.60786 11.2506 7.28516 11.8874L9.9828 14.3493L16.7149 8.20607C17.3921 7.56934 18.4717 8.55521 17.7743 9.17292Z"
        fill="#0370F3"
      />
    </svg>
  );
};
