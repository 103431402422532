import React from 'react';

export const GoogleMeet = ({ className }: { className?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M9.05029 7.99999L10.6099 9.73332L12.707 11.0364L13.0727 8.01066L12.707 5.05244L10.5697 6.19733L9.05029 7.99999Z"
        fill="#00832D"
      />
      <path
        d="M0 10.7556V13.3333C0 13.9227 0.490915 14.4 1.09702 14.4H3.74814L4.29665 12.4516L3.74814 10.7556L1.92892 10.2222L0 10.7556Z"
        fill="#0066DA"
      />
      <path
        d="M3.74814 1.6L0 5.24444L1.92892 5.77777L3.74814 5.24444L4.28751 3.57155L3.74814 1.6Z"
        fill="#E94235"
      />
      <path d="M3.74814 5.24444H0V10.7556H3.74814V5.24444Z" fill="#2684FC" />
      <path
        d="M15.1022 3.1431L12.707 5.05244V11.0364L15.1131 12.9547C15.4733 13.2284 15.9999 12.9787 15.9999 12.5333V3.55555C15.9999 3.10488 15.4615 2.85777 15.1022 3.1431ZM9.0503 7.99999V10.7555H3.74805V14.4H11.61C12.2161 14.4 12.707 13.9227 12.707 13.3333V11.0364L9.0503 7.99999Z"
        fill="#00AC47"
      />
      <path
        d="M11.61 1.6H3.74805V5.24444H9.0503V8L12.707 5.05422V2.66666C12.707 2.07733 12.2161 1.6 11.61 1.6Z"
        fill="#FFBA00"
      />
    </svg>
  );
};
