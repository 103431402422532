import React, { lazy } from 'react';

import { History as HistoryIcon } from '@assets/icons';

import type { RouteObject } from 'react-router-dom';

const History = lazy(() => import('@pages/Dashboard/History'));
const MeetingPage = lazy(() => import('@pages/Dashboard/History/MeetingPage'));

export const HistoryRoutes: RouteObject[] = [
  {
    path: 'history',
    element: (
      <React.Suspense>
        <History />
      </React.Suspense>
    ),
    handle: {
      label: 'History',
      icon: <HistoryIcon />,
      isMenuNavItem: true,
    },
  },
  {
    path: 'history/:meetingId',

    element: (
      <React.Suspense>
        <MeetingPage />
      </React.Suspense>
    ),
    handle: {},
  },
];
