import React from 'react';
import type { FC } from 'react';

interface DownloadProps {
  className?: string;
}

export const Download: FC<DownloadProps> = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12_671)">
        <path
          d="M12.5 10V12H4.49996V10H3.16663V12C3.16663 12.7334 3.76663 13.3334 4.49996 13.3334H12.5C13.2333 13.3334 13.8333 12.7334 13.8333 12V10H12.5ZM11.8333 7.33335L10.8933 6.39335L9.16663 8.11335V2.66669H7.83329V8.11335L6.10663 6.39335L5.16663 7.33335L8.49996 10.6667L11.8333 7.33335Z"
          fill="#F9FAFB"
        />
      </g>
      <defs>
        <clipPath id="clip0_12_671">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
