import React, { lazy } from 'react';

import { DollarSign } from '@assets/icons/DollarSign';

import type { RouteObject } from 'react-router-dom';

const Subscription = lazy(() => import('@pages/Dashboard/Subscription'));

export const SubscriptionRoutes: RouteObject[] = [
  {
    path: 'subscription',
    element: (
      <React.Suspense>
        <Subscription />
      </React.Suspense>
    ),
    handle: {
      label: 'Subscription',
      icon: <DollarSign />,
      isMenuNavItem: true,
    },
  },
];
