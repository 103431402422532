import { baseQuery } from '@store/utils';

import { createApi } from '@reduxjs/toolkit/query/react';

interface EditedTranscriptionPayload {
  meetingId: number;
  editedTranscription: string;
}
export const preModerationApi = createApi({
  reducerPath: 'preModeration',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    reproduceEditedTranscription: builder.mutation<
      unknown,
      EditedTranscriptionPayload
    >({
      query: (payload) => ({
        url: 'bot/reproduce-edited-transcription',
        method: 'POST',
        body: {
          ...payload,
        },
      }),
    }),
  }),
});

export const { useReproduceEditedTranscriptionMutation } = preModerationApi;
