import React from 'react';

export const CalendarOutline = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 2H12.6667V0.666666H11.3334V2H4.66671V0.666666H3.33337V2H2.66671C1.93337 2 1.33337 2.6 1.33337 3.33333V14C1.33337 14.7333 1.93337 15.3333 2.66671 15.3333H13.3334C14.0667 15.3333 14.6667 14.7333 14.6667 14V3.33333C14.6667 2.6 14.0667 2 13.3334 2ZM13.3334 14H2.66671V6.66667H13.3334V14ZM13.3334 5.33333H2.66671V3.33333H13.3334V5.33333Z"
        fill="#0370F3"
      />
    </svg>
  );
};
