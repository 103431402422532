import React, { lazy } from 'react';

import { FAQNav } from '@assets/icons';

import type { RouteObject } from 'react-router-dom';

const FAQ = lazy(() => import('@pages/Dashboard/FAQ'));

export const FAQRoutes: RouteObject[] = [
  {
    path: 'faq',
    element: (
      <React.Suspense>
        <FAQ />
      </React.Suspense>
    ),
    handle: {
      label: 'FAQ',
      icon: <FAQNav />,
      isMenuNavItem: true,
    },
  },
];
