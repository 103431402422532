import React from 'react';

export const Clock = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#0370F3" />
      <g clipPath="url(#clip0_136_5605)">
        <path
          d="M15.9906 6.625C10.8156 6.625 6.625 10.825 6.625 16C6.625 21.175 10.8156 25.375 15.9906 25.375C21.175 25.375 25.375 21.175 25.375 16C25.375 10.825 21.175 6.625 15.9906 6.625ZM16 23.5C11.8562 23.5 8.5 20.1438 8.5 16C8.5 11.8562 11.8562 8.5 16 8.5C20.1438 8.5 23.5 11.8562 23.5 16C23.5 20.1438 20.1438 23.5 16 23.5ZM16.4688 11.3125H15.0625V16.9375L19.9844 19.8906L20.6875 18.7375L16.4688 16.2344V11.3125Z"
          fill="#F9FAFB"
        />
      </g>
      <defs>
        <clipPath id="clip0_136_5605">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
