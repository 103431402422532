import React from 'react';

export const MSTeams = ({ className }: { className?: string }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <g clipPath="url(#clip0_2864_76300)">
        <rect x="2.5" y="2" width="20" height="20" rx="10" fill="white" />
        <path
          d="M15.9565 10.5938H20.4765C20.904 10.5938 21.2502 10.9388 21.2502 11.365V15.475C21.2499 15.848 21.1761 16.2172 21.0331 16.5616C20.8901 16.9061 20.6806 17.219 20.4166 17.4825C20.1527 17.7459 19.8394 17.9549 19.4947 18.0973C19.1501 18.2397 18.7807 18.3128 18.4077 18.3125H18.3952C18.0223 18.3128 17.6529 18.2397 17.3082 18.0973C16.9635 17.9549 16.6503 17.7459 16.3863 17.4825C16.1224 17.219 15.9129 16.9061 15.7699 16.5616C15.6268 16.2172 15.5531 15.848 15.5527 15.475V10.9988C15.5527 10.775 15.734 10.5938 15.9565 10.5938ZM19.0127 9.78125C20.0227 9.78125 20.8427 8.9625 20.8427 7.95375C20.8427 6.94375 20.0227 6.125 19.0115 6.125C18.0002 6.125 17.1802 6.94375 17.1802 7.95375C17.1802 8.9625 18.0002 9.78125 19.0115 9.78125H19.0127Z"
          fill="#5059C9"
        />
        <path
          d="M13.314 9.78125C13.6611 9.78175 14.0049 9.71384 14.3258 9.58142C14.6466 9.449 14.9383 9.25466 15.184 9.00949C15.4298 8.76433 15.6248 8.47315 15.758 8.15259C15.8912 7.83204 15.9599 7.48838 15.9602 7.14125C15.96 6.79392 15.8914 6.45003 15.7582 6.12924C15.625 5.80846 15.4299 5.51708 15.184 5.27176C14.9381 5.02645 14.6463 4.83202 14.3252 4.6996C14.0041 4.56717 13.66 4.49935 13.3127 4.5C12.9657 4.49968 12.622 4.5677 12.3013 4.7002C11.9806 4.83269 11.6891 5.02706 11.4434 5.27221C11.1978 5.51735 11.0029 5.80848 10.8698 6.12895C10.7367 6.44943 10.668 6.79299 10.6677 7.14C10.6677 8.59875 11.8515 9.78125 13.3127 9.78125H13.314ZM16.8415 10.5938H9.37896C9.1766 10.5987 8.98447 10.6837 8.84478 10.8302C8.70509 10.9767 8.62926 11.1726 8.63396 11.375V16.0625C8.60679 17.2775 9.06293 18.4535 9.90222 19.3324C10.7415 20.2113 11.8953 20.7212 13.1102 20.75C14.3253 20.7215 15.4795 20.2118 16.319 19.3328C17.1586 18.4539 17.6149 17.2777 17.5877 16.0625V11.375C17.59 11.2747 17.5726 11.1749 17.5363 11.0814C17.5001 10.9878 17.4457 10.9023 17.3764 10.8298C17.3071 10.7572 17.2242 10.699 17.1324 10.6585C17.0406 10.618 16.9418 10.596 16.8415 10.5938Z"
          fill="#7B83EB"
        />
        <path
          opacity="0.1"
          d="M13.518 10.5938V17.1625C13.517 17.3597 13.4382 17.5485 13.2986 17.6878C13.1591 17.8271 12.9702 17.9056 12.773 17.9063H8.99177C8.75518 17.3199 8.63339 16.6935 8.63302 16.0613V11.375C8.63069 11.2748 8.64816 11.175 8.68443 11.0816C8.72069 10.9881 8.77504 10.9027 8.84436 10.8302C8.91367 10.7578 8.9966 10.6997 9.08839 10.6593C9.18017 10.619 9.27902 10.5971 9.37927 10.595H13.5168L13.518 10.5938Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M13.11 10.5937V17.5687C13.1083 17.7657 13.0293 17.9541 12.8899 18.0933C12.7505 18.2325 12.5619 18.3112 12.365 18.3125H9.18372C9.04316 18.0547 8.93125 17.7822 8.84997 17.5C8.7078 17.0341 8.63535 16.5496 8.63497 16.0625V11.3725C8.63265 11.2724 8.65008 11.1727 8.68626 11.0793C8.72245 10.9859 8.77668 10.9006 8.84587 10.8281C8.91505 10.7557 8.99783 10.6976 9.08947 10.6572C9.18112 10.6168 9.27983 10.5948 9.37997 10.5925H13.1112L13.11 10.5937Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M13.11 10.5937V16.7562C13.1083 16.9532 13.0293 17.1416 12.8899 17.2808C12.7505 17.42 12.5619 17.4987 12.365 17.5H8.84997C8.7078 17.0341 8.63535 16.5496 8.63497 16.0625V11.3725C8.63265 11.2724 8.65008 11.1727 8.68626 11.0793C8.72245 10.9859 8.77668 10.9006 8.84587 10.8281C8.91505 10.7557 8.99783 10.6976 9.08947 10.6572C9.18112 10.6168 9.27983 10.5948 9.37997 10.5925H13.1112L13.11 10.5937Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M12.703 10.5937V16.7562C12.7014 16.9532 12.6223 17.1416 12.4829 17.2808C12.3435 17.42 12.155 17.4987 11.958 17.5H8.84802C8.70585 17.0341 8.6334 16.5496 8.63302 16.0625V11.3725C8.63069 11.2724 8.64812 11.1727 8.68431 11.0793C8.72049 10.9859 8.77473 10.9006 8.84391 10.8281C8.9131 10.7557 8.99588 10.6976 9.08752 10.6572C9.17916 10.6168 9.27788 10.5948 9.37802 10.5925L12.703 10.5937Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M13.5184 8.49375V9.77375C13.4497 9.7775 13.3847 9.78125 13.3147 9.78125C13.2459 9.78125 13.1809 9.7775 13.1109 9.77375C12.5527 9.73073 12.0225 9.51157 11.5968 9.14787C11.1711 8.78417 10.8718 8.2947 10.7422 7.75H12.7734C12.9704 7.75065 13.1591 7.82901 13.2986 7.96805C13.4381 8.10708 13.5171 8.29555 13.5184 8.4925V8.49375Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M13.1105 8.9V9.77375C12.6249 9.7366 12.159 9.56601 11.7641 9.28081C11.3693 8.9956 11.0609 8.60687 10.873 8.1575H12.3668C12.5635 8.15848 12.7519 8.23699 12.8912 8.37599C13.0304 8.51498 13.1092 8.70327 13.1105 8.9Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M13.1105 8.9V9.77375C12.6249 9.7366 12.159 9.56601 11.7641 9.28081C11.3693 8.9956 11.0609 8.60687 10.873 8.1575H12.3668C12.5635 8.15848 12.7519 8.23699 12.8912 8.37599C13.0304 8.51498 13.1092 8.70327 13.1105 8.9Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M12.7043 8.9V9.70875C12.2982 9.61285 11.9206 9.42219 11.6023 9.15235C11.284 8.88252 11.0341 8.54118 10.873 8.15625H11.9605C12.1575 8.15724 12.3461 8.23591 12.4854 8.37518C12.6246 8.51444 12.7033 8.70305 12.7043 8.9Z"
          fill="black"
        />
        <path
          d="M4.49625 8.15625H11.9575C12.37 8.15625 12.7037 8.49 12.7037 8.90125V16.3488C12.7037 16.4467 12.6844 16.5437 12.6469 16.6341C12.6094 16.7246 12.5544 16.8068 12.4851 16.876C12.4158 16.9452 12.3335 17 12.243 17.0374C12.1525 17.0748 12.0554 17.0939 11.9575 17.0938H4.495C4.39717 17.0938 4.30029 17.0745 4.2099 17.037C4.11951 16.9996 4.03739 16.9447 3.96821 16.8755C3.89903 16.8064 3.84415 16.7242 3.80671 16.6338C3.76927 16.5435 3.75 16.4466 3.75 16.3488V8.90125C3.75 8.48875 4.08375 8.15625 4.49625 8.15625Z"
          fill="url(#paint0_linear_2864_76300)"
        />
        <path
          d="M10.1892 10.9912H8.69797V15.045H7.74797V10.9912H6.26172V10.2037H10.188L10.1892 10.9912Z"
          fill="white"
        />
      </g>
      <rect
        x="1.5"
        y="1"
        width="22"
        height="22"
        rx="11"
        stroke="white"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2864_76300"
          x1="5.305"
          y1="7.575"
          x2="11.1325"
          y2="17.685"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A62C3" />
          <stop offset="0.5" stopColor="#4D55BD" />
          <stop offset="1" stopColor="#3940AB" />
        </linearGradient>
        <clipPath id="clip0_2864_76300">
          <rect x="2.5" y="2" width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
