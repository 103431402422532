import { adminApi } from '@store/admin';
import { botApi } from '@store/bot';
import { calendarApi } from '@store/calendar';
import { meetingsApi } from '@store/meetings';
import { preModerationApi } from '@store/preModeration/preModeration.api';
import rootReducer from '@store/reducers';
import { subscriptionsApi } from '@store/subscription';
import { userApi } from '@store/user';

import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      meetingsApi.middleware,
      adminApi.middleware,
      userApi.middleware,
      botApi.middleware,
      subscriptionsApi.middleware,
      calendarApi.middleware,
      preModerationApi.middleware,
    ),
});
export type RootState = ReturnType<typeof rootReducer>;
