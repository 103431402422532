import React from 'react';

export const PersonFilled = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#0370F3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9999 7C13.5137 7 11.4999 9.01375 11.4999 11.5C11.4999 13.9862 13.5137 16 15.9999 16C18.4862 16 20.4999 13.9862 20.4999 11.5C20.4999 9.01375 18.4862 7 15.9999 7ZM18.25 11.5C18.25 10.2625 17.2375 9.24999 16 9.24999C14.7625 9.24999 13.75 10.2625 13.75 11.5C13.75 12.7375 14.7625 13.75 16 13.75C17.2375 13.75 18.25 12.7375 18.25 11.5ZM22.7501 21.625C22.5251 20.8262 19.0376 19.375 16.0001 19.375C12.9626 19.375 9.47507 20.8262 9.25007 21.6362V22.75H22.7501V21.625ZM7 21.625C7 18.6325 12.9963 17.125 16 17.125C19.0037 17.125 25 18.6325 25 21.625V25H7V21.625Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};
