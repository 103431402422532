import React, { lazy } from 'react';

import { Person } from '@assets/icons';

import type { RouteObject } from 'react-router-dom';

const MobileSettingsPage = lazy(
  () => import('@pages/Dashboard/Profile/components/MobileSettingsPage'),
);

const Profile = lazy(() => import('@pages/Dashboard/Profile'));

export const ProfileRoutes: RouteObject[] = [
  {
    path: 'profile',
    element: (
      <React.Suspense>
        <Profile />
      </React.Suspense>
    ),
    handle: {
      label: 'Profile',
      icon: <Person />,
      isMenuNavItem: true,
    },
  },
  {
    path: 'profile/:settings',
    element: (
      <React.Suspense>
        <MobileSettingsPage />
      </React.Suspense>
    ),
    handle: {},
  },
];
