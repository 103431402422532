import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface Language {
  code: string;
  name: string;
}
interface AdminState {
  supportedLanguages: Language[] | null;
  freePlanId: string | null;
  vidbyPastedMeetingLink: string | null;
}
export const AdminAdapter = createEntityAdapter<AdminState>();

const initialState: AdminState = {
  supportedLanguages: null,
  freePlanId: null,
  vidbyPastedMeetingLink: null,
};

const adminSlice = createSlice({
  name: 'admin',
  initialState: AdminAdapter.getInitialState(initialState),
  reducers: {
    setSupportedLanguages: (state, action: PayloadAction<Language[]>) => {
      state.supportedLanguages = action.payload;
    },
    setFreePlanId: (state, action: PayloadAction<string>) => {
      state.freePlanId = action.payload;
    },
    setVidbyPastedMeetingLink: (state, action: PayloadAction<string>) => {
      state.vidbyPastedMeetingLink = action.payload;
    },
  },
});

export const {
  setSupportedLanguages,
  setFreePlanId,
  setVidbyPastedMeetingLink,
} = adminSlice.actions;
export default adminSlice.reducer;
