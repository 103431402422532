import { baseQuery } from '@store/utils';

import type { Attendee, Settings } from '@types';

import { createApi } from '@reduxjs/toolkit/query/react';

interface BotPayload {
  id: number;
  userSub?: string;
}
interface ConnectingToTestMeetingPayload {
  url: string;
  speakerLanguage: string;
}
interface UpdateBotSettingsPayload extends Settings {
  meetingId: number;
}
interface UpdateAutoDetectedLanguagePayload {
  meetingId: number;
  autoDetectedLanguages: string[];
}

interface UpdateParticipantsPayload {
  meetingId: number;
  participants: Attendee[];
}
interface UpdateTranslatedLanguagesPayload {
  meetingId: number;
  translatedLanguages: string[];
}
export const botApi = createApi({
  reducerPath: 'bot',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    connectBot: builder.mutation<unknown, BotPayload>({
      query: (payload) => ({
        url: 'bot/connecting',
        method: 'POST',
        body: {
          meetingId: payload.id,
        },
      }),
    }),
    disconnectBot: builder.mutation<unknown, BotPayload>({
      query: (payload) => ({
        url: 'bot/disconnecting',
        method: 'POST',
        body: {
          meetingId: payload.id,
        },
      }),
    }),
    connectingToTestMeeting: builder.mutation<
      unknown,
      ConnectingToTestMeetingPayload
    >({
      query: (payload) => ({
        url: `bot/connecting-to-test-meeting`,
        method: 'POST',
        body: { ...payload },
      }),
    }),

    updateBotSettings: builder.mutation<unknown, UpdateBotSettingsPayload>({
      query: (payload) => ({
        url: `bot/updating-bot-settings`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateAutoDetectedLanguage: builder.mutation<
      unknown,
      UpdateAutoDetectedLanguagePayload
    >({
      query: (payload) => ({
        url: `bot/update-auto-detected-language`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateParticipants: builder.mutation<unknown, UpdateParticipantsPayload>({
      query: (payload) => ({
        url: `bot/update-participants`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateTranslatedLanguages: builder.mutation<
      unknown,
      UpdateTranslatedLanguagesPayload
    >({
      query: (payload) => ({
        url: `bot/update-translated-languages`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useConnectBotMutation,
  useDisconnectBotMutation,
  useConnectingToTestMeetingMutation,
  useUpdateBotSettingsMutation,
  useUpdateAutoDetectedLanguageMutation,
  useUpdateParticipantsMutation,
  useUpdateTranslatedLanguagesMutation,
} = botApi;
