import React from 'react';

export const Pen = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.37236 6.01333L9.98569 6.62667L3.94569 12.6667H3.33236V12.0533L9.37236 6.01333ZM11.7724 2C11.6057 2 11.4324 2.06667 11.3057 2.19333L10.0857 3.41333L12.5857 5.91333L13.8057 4.69333C14.0657 4.43333 14.0657 4.01333 13.8057 3.75333L12.2457 2.19333C12.1124 2.06 11.9457 2 11.7724 2ZM9.37236 4.12667L1.99902 11.5V14H4.49902L11.8724 6.62667L9.37236 4.12667Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};
