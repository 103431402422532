import type { PayloadAction } from '@reduxjs/toolkit';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

interface CurrentMeetingPreModeration {
  id: number;
  transcription: string;
}
interface PreModerationSliceProps {
  meetingsPreModeration: CurrentMeetingPreModeration[] | null;
}
const initialState: PreModerationSliceProps = {
  meetingsPreModeration: null,
};

export const PreModerationAdapter = createEntityAdapter();
const preModerationSlice = createSlice({
  name: 'preModeration',
  initialState: PreModerationAdapter.getInitialState(initialState),
  reducers: {
    updateMeetingsPreModeration: (
      state,
      action: PayloadAction<CurrentMeetingPreModeration>,
    ) => {
      if (
        !state?.meetingsPreModeration ||
        state.meetingsPreModeration.length === 0
      ) {
        state.meetingsPreModeration = [action.payload];
        return;
      }

      if (
        !state.meetingsPreModeration
          .map((meeting) => meeting.id)
          .indexOf(action.payload.id)
      ) {
        state.meetingsPreModeration = [
          ...state.meetingsPreModeration,
          action.payload,
        ];
      }
    },
    removeMeetingCurrentMessage: (state, action: PayloadAction<number>) => {
      if (state?.meetingsPreModeration) {
        state.meetingsPreModeration = state.meetingsPreModeration.filter(
          (meeting) => meeting.id !== action.payload,
        );
      }
    },
  },
});

export default preModerationSlice.reducer;

export const { updateMeetingsPreModeration, removeMeetingCurrentMessage } =
  preModerationSlice.actions;
