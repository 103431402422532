import React, { lazy } from 'react';

import type { RouteObject } from 'react-router-dom';

const Pricing = lazy(() => import('@pages/Landing/Pricing'));

export const PricingRoutes: RouteObject[] = [
  {
    path: 'pricing',
    element: (
      <React.Suspense>
        <Pricing />
      </React.Suspense>
    ),
    handle: {
      label: 'Pricing',
    },
  },
];
