import { baseQuery } from '@store/utils';

import { createApi } from '@reduxjs/toolkit/query/react';

interface CalendarResponse<Type> {
  data: Type;
  meta: {
    count: number;
  };
}
interface GrantingAccessResponse {
  url: string;
}
interface RevokingAccessPayload {
  accessToken: string;
  refreshToken: string;
}
export const calendarApi = createApi({
  reducerPath: 'calendar',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    grantingAccess: builder.mutation<
      CalendarResponse<GrantingAccessResponse>,
      unknown
    >({
      query: () => ({
        url: 'calendar/granting-access',
        method: 'POST',
      }),
    }),
    revokingAccess: builder.mutation<unknown, RevokingAccessPayload>({
      query: (payload) => ({
        url: 'calendar/revoking-access',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const { useRevokingAccessMutation, useGrantingAccessMutation } =
  calendarApi;
