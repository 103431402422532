import React from 'react';

export const Subscription = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#0370F3" />
      <g clipPath="url(#clip0_585_22011)">
        <path
          d="M16.229 20.443C15.503 20.443 14.8155 20.3275 14.1665 20.0965C13.5175 19.8545 12.962 19.497 12.5 19.024L13.1765 17.803C13.6715 18.243 14.1665 18.562 14.6615 18.76C15.1675 18.958 15.668 19.057 16.163 19.057C16.669 19.057 17.0815 18.9635 17.4005 18.7765C17.7195 18.5785 17.879 18.2925 17.879 17.9185C17.879 17.6435 17.7965 17.44 17.6315 17.308C17.4665 17.176 17.2245 17.0605 16.9055 16.9615C16.5975 16.8625 16.2235 16.7525 15.7835 16.6315C15.1675 16.4445 14.6505 16.252 14.2325 16.054C13.8145 15.845 13.501 15.592 13.292 15.295C13.083 14.998 12.9785 14.6185 12.9785 14.1565C12.9785 13.5955 13.1215 13.117 13.4075 12.721C13.6935 12.325 14.0895 12.0225 14.5955 11.8135C15.1125 11.6045 15.7065 11.5 16.3775 11.5C17.0045 11.5 17.582 11.599 18.11 11.797C18.649 11.984 19.122 12.281 19.529 12.688L18.7535 13.8925C18.3685 13.5405 17.9725 13.282 17.5655 13.117C17.1695 12.952 16.7405 12.8695 16.2785 12.8695C16.0145 12.8695 15.7615 12.9025 15.5195 12.9685C15.2775 13.0235 15.074 13.1335 14.909 13.2985C14.755 13.4525 14.678 13.6725 14.678 13.9585C14.678 14.1895 14.744 14.3765 14.876 14.5195C15.008 14.6625 15.206 14.7835 15.47 14.8825C15.734 14.9815 16.0585 15.0915 16.4435 15.2125C17.1145 15.4105 17.6865 15.6085 18.1595 15.8065C18.6325 15.9935 18.9955 16.241 19.2485 16.549C19.5125 16.846 19.6445 17.2585 19.6445 17.7865C19.6445 18.6225 19.331 19.277 18.704 19.75C18.088 20.212 17.263 20.443 16.229 20.443Z"
          fill="#F9FAFB"
        />
        <line
          x1="16.4"
          y1="10"
          x2="16.4"
          y2="22"
          stroke="#F9FAFB"
          strokeWidth="1.8"
        />
        <rect
          x="6.9"
          y="6.9"
          width="18.2"
          height="18.2"
          rx="9.1"
          stroke="#F9FAFB"
          strokeWidth="1.8"
        />
      </g>
      <defs>
        <clipPath id="clip0_585_22011">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
