import { baseQuery } from '@store/utils';

import type { Plan } from '@types';

import { createApi, retry } from '@reduxjs/toolkit/query/react';

const staggeredBaseQuery = retry(baseQuery);

export interface SubscriptionsResponse<Type> {
  data: Type;
  meta: {
    count: number;
  };
}
export interface SubscriptionInfo {
  name: string | null;
  price: number | null;
  remainingMinutes: number | null;
  updatedAt: string | null;
  nextBillingDay: number | null;
  status: 'active' | 'canceled' | 'past_due' | null;
  paymentMethod: {
    lastFour: string | null;
    expirationMonth: number | null;
    expirationYear: number | null;
  };
}

interface UpdatedPaymentMethodInfo {
  data: {
    paymentMethod: {
      expirationMonth: number;
      expirationYear: number;
      lastFour: string;
    };
    updatedAt: string;
  };
}
interface AdditionalMinutesDataResponse {
  paymentIntentId: string;
  clientSecret: string;
}

export const subscriptionsApi = createApi({
  reducerPath: 'subscription',
  baseQuery: staggeredBaseQuery,
  endpoints: (builder) => ({
    getSubscriptionsList: builder.query<
      SubscriptionsResponse<Plan[]>,
      undefined
    >({
      query: () => ({
        url: `subscription`,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    createSubscription: builder.mutation<
      { data: { clientSecret: string; subscriptionId: string } },
      { priceId?: string | null; productId?: string }
    >({
      query: (payload) => ({
        url: 'subscription/creating',
        method: 'POST',
        body: {
          ...payload,
        },
      }),
      extraOptions: { maxRetries: 0 },
    }),
    cancelSubscription: builder.mutation<unknown, string>({
      query: (subscriptionId) => ({
        url: 'subscription/canceling',
        method: 'POST',
        body: {
          subscriptionId,
        },
      }),
      extraOptions: { maxRetries: 0 },
    }),
    getUserSubscriptionInfo: builder.query<
      SubscriptionsResponse<SubscriptionInfo>,
      unknown
    >({
      query: () => ({
        url: `subscription/info`,
      }),
    }),
    updatePaymentCard: builder.mutation<UpdatedPaymentMethodInfo, string>({
      query: (token) => ({
        url: `subscription/changing-payment-method?token=${token}`,
        method: 'POST',
      }),
      extraOptions: { maxRetries: 0 },
    }),
    createAddingMinutesIntent: builder.mutation<
      SubscriptionsResponse<AdditionalMinutesDataResponse>,
      string
    >({
      query: (priceId) => ({
        url: `subscription/adding-minutes`,
        method: 'POST',
        body: {
          priceId: priceId,
        },
      }),
      extraOptions: { maxRetries: 0 },
    }),
    removeAddingMinutesIntent: builder.mutation<
      SubscriptionsResponse<AdditionalMinutesDataResponse>,
      string
    >({
      query: (paymentIntentId) => ({
        url: `subscription/canceling-adding-minutes`,
        method: 'POST',
        body: {
          paymentIntentId: paymentIntentId,
        },
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const {
  useGetSubscriptionsListQuery,
  useCreateSubscriptionMutation,
  useCancelSubscriptionMutation,
  useGetUserSubscriptionInfoQuery,
  useLazyGetUserSubscriptionInfoQuery,
  useUpdatePaymentCardMutation,
  useCreateAddingMinutesIntentMutation,
  useRemoveAddingMinutesIntentMutation,
} = subscriptionsApi;
