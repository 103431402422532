import React from 'react';

export const FilesNotFound = () => {
  return (
    <svg
      width="159"
      height="164"
      viewBox="0 0 159 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1047_8751)">
        <path d="M143.805 30.0212H0V159.782H143.805V30.0212Z" fill="#F4F6F8" />
        <path d="M143.805 30.0212H0V45.3544H143.805V30.0212Z" fill="#68A9F8" />
        <path
          d="M11.5928 37.663C11.5928 39.3006 10.2608 40.5907 8.68213 40.5907C7.10347 40.5907 5.77148 39.2509 5.77148 37.663C5.77148 36.0255 7.10347 34.7354 8.68213 34.7354C10.2608 34.7354 11.5928 36.0751 11.5928 37.663Z"
          fill="#EB725F"
        />
        <path
          d="M21.459 37.663C21.459 39.3006 20.127 40.5907 18.5483 40.5907C16.9697 40.5907 15.6377 39.2509 15.6377 37.663C15.6377 36.0255 16.9697 34.7354 18.5483 34.7354C20.127 34.7354 21.459 36.0751 21.459 37.663Z"
          fill="#F9AB43"
        />
        <path
          d="M31.3262 37.663C31.3262 39.3006 29.9942 40.5907 28.4155 40.5907C26.8369 40.5907 25.5049 39.2509 25.5049 37.663C25.5049 36.0255 26.8369 34.7354 28.4155 34.7354C29.9942 34.7354 31.3262 36.0751 31.3262 37.663Z"
          fill="#6E7FDD"
        />
        <path
          d="M122.741 89.6665C123.432 87.9298 122.445 86.4907 120.57 86.4907H51.3563C49.4817 86.4907 47.3604 87.9298 46.6697 89.6665L31.3272 128.818C30.6365 130.555 31.6232 131.994 33.4978 131.994H102.712C104.587 131.994 106.708 130.555 107.399 128.818L122.741 89.6665Z"
          fill="#B3D4FB"
        />
        <path
          d="M28.0214 125.444L43.3639 86.3419C44.0546 84.6051 46.1759 83.1661 48.0506 83.1661H102.366V79.6926C102.366 77.8069 100.837 76.2687 98.9622 76.2687H74.7397C72.865 76.2687 70.5957 74.9289 69.7077 73.2417L66.4024 67.1383C65.5144 65.5007 63.2451 64.1113 61.3705 64.1113H30.1921C28.3174 64.1113 26.7881 65.6496 26.7881 67.5352V125.245C26.7881 126.883 27.9721 128.322 29.5507 128.62C28.0707 128.272 27.4294 126.982 28.0214 125.444Z"
          fill="#B3D4FB"
        />
        <path
          d="M78.2914 97.3083C71.1875 97.3083 65.3662 103.114 65.3662 110.309C65.3662 117.455 71.1382 123.31 78.2914 123.31C85.3954 123.31 91.2167 117.504 91.2167 110.309C91.2167 103.114 85.4447 97.3083 78.2914 97.3083ZM84.6061 114.775L82.7314 116.661L78.2914 112.195L73.8515 116.661L71.9768 114.775L76.4168 110.309L71.9768 105.843L73.8515 103.958L78.2914 108.424L82.7314 103.958L84.6061 105.843L80.1661 110.309L84.6061 114.775Z"
          fill="#F4F6F8"
        />
        <path
          d="M136.029 139.389L66.2939 159.412L67.5127 163.706L137.247 143.683L136.029 139.389Z"
          fill="#68A9F8"
        />
        <path
          d="M136.043 139.373L104.212 148.513L105.431 152.808L137.262 143.668L136.043 139.373Z"
          fill="#B3D4FB"
        />
        <path
          d="M138.542 163.917C150.84 162.866 159.962 151.986 158.917 139.616C157.872 127.246 147.056 118.07 134.758 119.121C122.46 120.172 113.337 131.052 114.382 143.422C115.427 155.792 126.244 164.968 138.542 163.917Z"
          fill="#B3D4FB"
        />
        <path
          d="M153.044 148.872C157.082 139.76 153.013 129.08 143.954 125.018C134.896 120.956 124.278 125.05 120.24 134.162C116.202 143.273 120.271 153.953 129.33 158.015C138.388 162.077 149.006 157.983 153.044 148.872Z"
          fill="#F4F6F8"
        />
        <path
          d="M150.169 137.651L148.048 138.246C146.272 131.944 139.711 128.272 133.446 130.059L132.854 127.925C140.253 125.791 148.048 130.158 150.169 137.651Z"
          fill="#FEFEFE"
        />
        <path
          d="M119.977 29.1777L98.6156 22.6772L85.7891 65.3024L118.99 75.4252L128.363 44.362L119.977 29.1777Z"
          fill="#B3D4FB"
        />
        <path
          d="M117.855 46.0491L116.326 43.171L109.469 46.843L105.818 39.9952L102.957 41.4839L106.607 48.3813L99.75 52.0533L101.279 54.9314L108.137 51.2594L111.787 58.1568L114.649 56.6185L110.998 49.7211L117.855 46.0491Z"
          fill="#F4F6F8"
        />
        <path
          d="M63.2934 0L41.5869 5.30953L52.0948 48.5797L85.7892 40.3425L78.1426 8.78306L63.2934 0Z"
          fill="#B3D4FB"
        />
        <path
          d="M69.9538 15.6309L67.1912 13.9437L63.1459 20.593L56.5352 16.5241L54.8086 19.3029L61.4685 23.3719L57.4232 30.0212L60.1859 31.7579L64.2312 25.059L70.8418 29.128L72.5191 26.3492L65.9085 22.2802L69.9538 15.6309Z"
          fill="#F4F6F8"
        />
      </g>
      <defs>
        <clipPath id="clip0_1047_8751">
          <rect width="159" height="164" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
