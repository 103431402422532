import React, { lazy } from 'react';

import App from '../App';

import { SignInRoutes } from '@pages/Auth/SignIn/routes';
import { FAQRoutes } from '@pages/Dashboard/FAQ/routes';
import { HistoryRoutes } from '@pages/Dashboard/History/routes';
import { HomeRoutes } from '@pages/Dashboard/Home/routes';
import { ProfileRoutes } from '@pages/Dashboard/Profile/routes';
import { SubscriptionRoutes } from '@pages/Dashboard/Subscription/routes';
import { PricingRoutes } from '@pages/Landing/Pricing/routes';
import { createBrowserRouter } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

const DashboardLayout = lazy(() => import('@layouts/Dashboard'));
const AuthLayout = lazy(() => import('@layouts/Auth'));
const LandingLayout = lazy(() => import('@layouts/Landing'));
const NotFound = lazy(() => import('@pages/NotFound'));
export const DashboardRoutes: RouteObject[] = [
  ...HomeRoutes,
  ...HistoryRoutes,
  ...SubscriptionRoutes,
  ...ProfileRoutes,
  ...FAQRoutes,
];
export const AuthRoutes: RouteObject[] = [...SignInRoutes];
export const LandingRoutes: RouteObject[] = [...PricingRoutes];

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <React.Suspense>
        <App />
      </React.Suspense>
    ),
    children: [
      {
        path: '/',
        element: <DashboardLayout />,
        children: DashboardRoutes,
      },
      {
        path: '/',
        element: <AuthLayout />,
        children: AuthRoutes,
      },
      {
        path: '/',
        element: <LandingLayout />,
        children: LandingRoutes,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);
