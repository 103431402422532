import React from 'react';

export const FAQNav = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M10.9654 15.7692C10.9654 14.6372 11.0958 13.7705 11.3567 13.1692C11.6176 12.568 12.1622 11.9013 12.9904 11.1692C13.6353 10.5949 14.124 10.074 14.4567 9.60673C14.7894 9.13943 14.9558 8.60962 14.9558 8.0173C14.9558 7.2314 14.6881 6.57819 14.1529 6.05768C13.6176 5.53716 12.8807 5.2769 11.9423 5.2769C11.0858 5.2769 10.4192 5.50639 9.94226 5.96535C9.46533 6.42432 9.10315 6.93649 8.85571 7.50188L7.03076 6.69998C7.41923 5.74229 8.02564 4.94391 8.84999 4.30483C9.67434 3.66573 10.7051 3.34618 11.9423 3.34618C13.5576 3.34618 14.8009 3.80163 15.6721 4.71253C16.5432 5.62341 16.9788 6.70449 16.9788 7.95578C16.9788 8.73783 16.8092 9.42789 16.4701 10.026C16.131 10.624 15.6166 11.2468 14.9269 11.8942C14.0589 12.6904 13.5214 13.3183 13.3144 13.7779C13.1073 14.2375 13.0038 14.9013 13.0038 15.7692H10.9654ZM11.9423 21.5C11.5333 21.5 11.1811 21.3522 10.8856 21.0567C10.5901 20.7612 10.4423 20.409 10.4423 20C10.4423 19.591 10.5901 19.2388 10.8856 18.9433C11.1811 18.6478 11.5333 18.5 11.9423 18.5C12.3512 18.5 12.7035 18.6478 12.999 18.9433C13.2945 19.2388 13.4422 19.591 13.4422 20C13.4422 20.409 13.2945 20.7612 12.999 21.0567C12.7035 21.3522 12.3512 21.5 11.9423 21.5Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};
