import React from 'react';

export const DollarSign = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.972 17.924C11.004 17.924 10.0873 17.77 9.222 17.462C8.35667 17.1393 7.616 16.6627 7 16.032L7.902 14.404C8.562 14.9907 9.222 15.416 9.882 15.68C10.5567 15.944 11.224 16.076 11.884 16.076C12.5587 16.076 13.1087 15.9513 13.534 15.702C13.9593 15.438 14.172 15.0567 14.172 14.558C14.172 14.1913 14.062 13.92 13.842 13.744C13.622 13.568 13.2993 13.414 12.874 13.282C12.4633 13.15 11.9647 13.0033 11.378 12.842C10.5567 12.5927 9.86733 12.336 9.31 12.072C8.75267 11.7933 8.33467 11.456 8.056 11.06C7.77733 10.664 7.638 10.158 7.638 9.542C7.638 8.794 7.82867 8.156 8.21 7.628C8.59133 7.1 9.11933 6.69667 9.794 6.418C10.4833 6.13933 11.2753 6 12.17 6C13.006 6 13.776 6.132 14.48 6.396C15.1987 6.64533 15.8293 7.04133 16.372 7.584L15.338 9.19C14.8247 8.72067 14.2967 8.376 13.754 8.156C13.226 7.936 12.654 7.826 12.038 7.826C11.686 7.826 11.3487 7.87 11.026 7.958C10.7033 8.03133 10.432 8.178 10.212 8.398C10.0067 8.60333 9.904 8.89667 9.904 9.278C9.904 9.586 9.992 9.83533 10.168 10.026C10.344 10.2167 10.608 10.378 10.96 10.51C11.312 10.642 11.7447 10.7887 12.258 10.95C13.1527 11.214 13.9153 11.478 14.546 11.742C15.1767 11.9913 15.6607 12.3213 15.998 12.732C16.35 13.128 16.526 13.678 16.526 14.382C16.526 15.4967 16.108 16.3693 15.272 17C14.4507 17.616 13.3507 17.924 11.972 17.924Z"
        fill="#F9FAFB"
      />
      <line
        x1="11.9"
        y1="4"
        x2="11.9"
        y2="20"
        stroke="#F9FAFB"
        strokeWidth="1.8"
      />
      <rect
        x="1.9"
        y="1.9"
        width="20.2"
        height="20.2"
        rx="10.1"
        stroke="#F9FAFB"
        strokeWidth="1.8"
      />
    </svg>
  );
};
