import React from 'react';

export const LinkSimple = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_296_3707)">
        <path
          d="M5.57994 18.6291C3.74865 16.7978 3.74927 13.8307 5.57994 12L7.73441 9.84555C8.00993 9.57002 8.45326 9.57002 8.72878 9.84555C9.0043 10.1211 9.0043 10.5644 8.72878 10.8399L6.57431 12.9944C5.29282 14.2759 5.29199 16.3524 6.57431 17.6348C7.85663 18.9171 9.93321 18.9163 11.2147 17.6348L13.3692 15.4803C13.6447 15.2048 14.088 15.2048 14.3635 15.4803C14.6391 15.7558 14.6391 16.1991 14.3635 16.4747L12.2091 18.6291C10.3784 20.4598 7.41124 20.4604 5.57994 18.6291ZM18.8382 12L16.6837 14.1545C16.4082 14.43 15.9649 14.43 15.6894 14.1545C15.4138 13.879 15.4138 13.4356 15.6894 13.1601L17.8438 11.0056C19.1261 9.72332 19.1261 7.64758 17.8438 6.36526C16.5615 5.08293 14.4858 5.08293 13.2034 6.36526L11.049 8.51972C10.7734 8.79524 10.3301 8.79524 10.0546 8.51972C9.77908 8.2442 9.77908 7.80088 10.0546 7.52535L12.2091 5.37089C14.0404 3.53959 17.0069 3.53959 18.8382 5.37089C20.6695 7.20218 20.6695 10.1687 18.8382 12ZM13.8664 9.34836C14.1419 9.07284 14.5852 9.07284 14.8607 9.34836C15.1362 9.62388 15.1362 10.0672 14.8607 10.3427L10.5518 14.6517C10.2763 14.9272 9.83294 14.9272 9.55742 14.6517C9.2819 14.3761 9.2819 13.9328 9.55742 13.6573L13.8664 9.34836Z"
          fill="#8689A5"
        />
      </g>
      <defs>
        <clipPath id="clip0_296_3707">
          <rect
            width="18.75"
            height="15"
            fill="white"
            transform="translate(0.276367 13.3257) rotate(-45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
