import React from 'react';

import awsconfig from './aws.config';

import { store } from '@store/index';

import { Amplify } from 'aws-amplify';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { Outlet } from 'react-router-dom';

Amplify.configure(awsconfig);
const App = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <Outlet />
        <Toaster />
      </Provider>
    </React.StrictMode>
  );
};

export default App;
