import { baseQuery } from '@store/utils';

import { createApi } from '@reduxjs/toolkit/query/react';

interface AdminInfoData {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  value: unknown;
}

interface GetAdminInfoResponse {
  data: AdminInfoData[];
  meta: {
    count: number;
  };
}

interface GetSupportedLanguagesResponse {
  data: AdminInfoData;
}

export const adminApi = createApi({
  reducerPath: 'admin',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAdminInfo: builder.query<GetAdminInfoResponse, unknown>({
      query: () => ({
        url: 'admin/info',
      }),
    }),
    getLanguagesList: builder.query<GetSupportedLanguagesResponse, unknown>({
      query: () => ({
        url: 'admin/languages',
      }),
    }),
  }),
});

export const { useGetAdminInfoQuery, useGetLanguagesListQuery } = adminApi;
