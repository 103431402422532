import React from 'react';

export const FinishedMeetings = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#0370F3" />
      <g clipPath="url(#clip0_562_11632)">
        <path
          d="M16.0005 6.01465C14.0255 6.01465 12.0948 6.60031 10.4526 7.69756C8.81049 8.79482 7.53058 10.3544 6.77478 12.1791C6.01898 14.0037 5.82122 16.0115 6.20653 17.9486C6.59183 19.8857 7.54289 21.665 8.93943 23.0615C10.336 24.458 12.1153 25.4091 14.0523 25.7944C15.9894 26.1797 17.9972 25.982 19.8219 25.2262C21.6465 24.4704 23.2061 23.1904 24.3034 21.5483C25.4006 19.9061 25.9863 17.9755 25.9863 16.0005C25.9832 13.353 24.9301 10.8149 23.0581 8.94288C21.186 7.07085 18.6479 6.01777 16.0005 6.01465ZM16.0005 24.1707C14.3846 24.1707 12.8049 23.6915 11.4613 22.7937C10.1178 21.896 9.07056 20.62 8.45218 19.1271C7.83379 17.6342 7.67199 15.9914 7.98724 14.4065C8.30249 12.8217 9.08063 11.3659 10.2233 10.2233C11.3659 9.08063 12.8217 8.30249 14.4065 7.98724C15.9914 7.67199 17.6342 7.83379 19.1271 8.45217C20.62 9.07056 21.896 10.1178 22.7938 11.4613C23.6915 12.8049 24.1707 14.3845 24.1707 16.0005C24.1683 18.1666 23.3067 20.2433 21.775 21.775C20.2433 23.3067 18.1666 24.1683 16.0005 24.1707Z"
          fill="#F9FAFB"
        />
        <path
          d="M19.9257 13.0611L15.1198 17.4666L13.011 15.3578C12.9272 15.2711 12.8271 15.202 12.7163 15.1544C12.6055 15.1068 12.4864 15.0818 12.3659 15.0807C12.2453 15.0797 12.1258 15.1026 12.0142 15.1483C11.9027 15.1939 11.8013 15.2613 11.7161 15.3466C11.6308 15.4318 11.5634 15.5332 11.5178 15.6447C11.4722 15.7563 11.4492 15.8758 11.4502 15.9964C11.4513 16.1169 11.4763 16.236 11.5239 16.3468C11.5715 16.4575 11.6406 16.5577 11.7273 16.6415L14.4507 19.3649C14.6162 19.5303 14.8391 19.6255 15.073 19.6306C15.3069 19.6357 15.5338 19.5502 15.7062 19.3921L21.153 14.3992C21.3305 14.2364 21.436 14.0098 21.4464 13.7693C21.4568 13.5287 21.3712 13.2939 21.2084 13.1165C21.0457 12.939 20.8191 12.8335 20.5785 12.8231C20.338 12.8127 20.1031 12.8983 19.9257 13.0611Z"
          fill="#F9FAFB"
        />
      </g>
      <defs>
        <clipPath id="clip0_562_11632">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
