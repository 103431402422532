import { userApi } from '@store/user';

import type { Attendee, Meeting } from '@types';

import type { UpdatedAttendeesMessage } from '@hooks/useWebsocketService';

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface StoreMeeting extends Meeting {
  isActive?: boolean;
  isUpcoming?: boolean;
}
interface MeetingSliceProps {
  selectedMeeting: null | Meeting;
  meetings: null | Meeting[];
  currentAttendeesMessage: null | UpdatedAttendeesMessage;
}
const initialState: MeetingSliceProps = {
  meetings: null,
  selectedMeeting: null,
  currentAttendeesMessage: null,
};

export interface SocketTranslateToLanguages {
  id: number;
  userSub: string;
  translatedLanguages: string[];
}
export const MeetingsAdapter = createEntityAdapter<MeetingSliceProps>();

const meetingsSlice = createSlice({
  name: 'meetings',
  initialState: MeetingsAdapter.getInitialState(initialState),
  reducers: {
    initMeetings: (state, action: PayloadAction<Meeting[]>) => {
      state.meetings = action.payload;
    },
    updateCurrentAttendeesMessage: (state, action) => {
      state.currentAttendeesMessage = action.payload;
    },
    selectMeeting: (state, action) => {
      if (!state.meetings) {
        return;
      }
      state.meetings = state.meetings.map((meet) => {
        if (action.payload.id === meet.id) {
          return { ...meet, isActive: true };
        }
        return { ...meet, isActive: false };
      });
      state.selectedMeeting = { ...action.payload, isActive: true };
    },
    updateSelectedMeeting: (state, action: PayloadAction<Meeting>) => {
      if (!state?.selectedMeeting || !state?.meetings) {
        return;
      }
      state.selectedMeeting = action.payload;

      const foundMeeting = state.meetings.find(
        (meeting) => meeting.id === action.payload.id,
      );

      if (foundMeeting) {
        const foundIndex = state.meetings.indexOf(foundMeeting);

        state.meetings[foundIndex] = {
          ...state.meetings[foundIndex],
          ...action.payload,
        };
      }
    },
    updateStoreMeeting: (state, action: PayloadAction<Meeting>) => {
      if (!state?.meetings) {
        return;
      }

      if (
        state?.selectedMeeting &&
        state.selectedMeeting.id === action.payload.id
      ) {
        state.selectedMeeting = { ...action.payload, isActive: true };
      }

      const meetingIndex = state.meetings.findIndex(
        (meeting) => meeting.id === action.payload.id,
      );
      state.meetings[meetingIndex] = action.payload;
    },
    updateSelectedMeetingAttendees: (
      state,
      action: PayloadAction<Attendee[]>,
    ) => {
      if (!state?.selectedMeeting || !state?.selectedMeeting?.attendees) {
        return;
      }
      state.selectedMeeting.attendees = action.payload;
    },
    meetingAutoSelect: (state) => {
      const currentMeeting = state?.meetings?.find(
        (meeting) => meeting.status === 'CURRENT',
      );

      if (currentMeeting) {
        state.selectedMeeting = { ...currentMeeting, isActive: true };
        return state;
      }
      const upcomingMeeting = state?.meetings?.find(
        (meeting) => meeting.status === 'UPCOMING',
      );
      if (upcomingMeeting) {
        state.selectedMeeting = { ...upcomingMeeting, isActive: true };
        return state;
      }

      state.selectedMeeting = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints?.updateMeetingsSettings.matchFulfilled,
      (state, { payload }) => {
        if (!state.meetings) {
          return;
        }
        state.meetings = state.meetings.map((meeting) => {
          if (state.selectedMeeting) {
            state.selectedMeeting.settings =
              payload.data.defaultMeetingsSettings;
          }
          return {
            ...meeting,
            settings: payload.data.defaultMeetingsSettings,
          };
        });
      },
    );
  },
});

export default meetingsSlice.reducer;
export const {
  initMeetings,
  selectMeeting,
  updateStoreMeeting,
  updateCurrentAttendeesMessage,
  updateSelectedMeeting,
  updateSelectedMeetingAttendees,
  meetingAutoSelect,
} = meetingsSlice.actions;
