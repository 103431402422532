import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Auth } from 'aws-amplify';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: async (headers) => {
    const currentSession = await Auth.currentSession();
    const token = currentSession.getIdToken().getJwtToken();

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});
