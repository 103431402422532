import { baseQuery } from '@store/utils';

import type { Settings, User } from '@types';
import type { CalendarSettings } from '@types';

import { createApi } from '@reduxjs/toolkit/query/react';

export interface UserResponse<Type> {
  data: Type;
  meta: {
    count?: number;
  };
}

interface GoogleTokens {
  accessToken: string;
  refreshToken: string;
}
interface UserLanguagesSettings {
  autoDetectedLanguages: string[];
  primaryLanguage: string | null;
}
export const userApi = createApi({
  reducerPath: 'user',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getUser: builder.query<UserResponse<User>, undefined>({
      query: () => ({
        url: `user`,
      }),
    }),
    updateGoogleToken: builder.mutation<
      UserResponse<GoogleTokens>,
      GoogleTokens
    >({
      query: (payload) => ({
        url: 'user/refreshing-google-token',
        method: 'POST',
        body: payload,
      }),
    }),
    updatingCalendarsSettings: builder.mutation<
      unknown,
      { calendarsSettings: CalendarSettings[] }
    >({
      query: (payload) => ({
        url: 'user/updating-calendars-settings',
        method: 'PATCH',
        body: payload,
      }),
    }),
    updateMeetingsSettings: builder.mutation<UserResponse<User>, Settings>({
      query: (payload) => ({
        url: 'user/meetings-settings',
        method: 'PATCH',
        body: payload,
      }),
    }),
    updateLanguagesSettings: builder.mutation<
      UserResponse<User>,
      UserLanguagesSettings
    >({
      query: (payload) => ({
        url: 'user/languages-settings',
        method: 'PATCH',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateGoogleTokenMutation,
  useUpdatingCalendarsSettingsMutation,
  useUpdateMeetingsSettingsMutation,
  useUpdateLanguagesSettingsMutation,
} = userApi;
