import { userApi } from '@store/user';

import type { User } from '@types';

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  user: User | null;
  jwtToken: string | null;
  refreshToken: string | null;
  accessToken: string | null;
  connectionId: null | string;
}

export const AuthAdapter = createEntityAdapter<AuthState>();

const initialState: AuthState = {
  user: null,
  jwtToken: null,
  refreshToken: null,
  accessToken: null,
  connectionId: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: AuthAdapter.getInitialState(initialState),
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    updateUser: (state, action: PayloadAction<User>) => {
      if (state?.user) {
        state.user = { ...state.user, ...action.payload };
      } else {
        state.user = action.payload;
      }
    },
    setJwtToken: (state, action: PayloadAction<string>) => {
      state.jwtToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string | null>) => {
      state.refreshToken = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    setConnectionId: (state, action: PayloadAction<string>) => {
      state.connectionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints?.updateLanguagesSettings.matchFulfilled,
      (state, { payload }) => {
        if (!state.user) {
          return;
        }
        state.user.languagesSettings = payload.data.languagesSettings;
      },
    );
  },
});

export const {
  setUser,
  updateUser,
  setJwtToken,
  setAccessToken,
  setConnectionId,
  setRefreshToken,
} = authSlice.actions;
export default authSlice.reducer;
