import React, { lazy } from 'react';

import { House } from '@assets/icons';

import type { RouteObject } from 'react-router-dom';

const Home = lazy(() => import('@pages/Dashboard/Home'));

export const HomeRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      <React.Suspense>
        <Home />
      </React.Suspense>
    ),
    handle: {
      label: 'Home',
      icon: <House />,
      isMenuNavItem: true,
    },
  },
];
