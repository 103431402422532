import React, { lazy } from 'react';

import type { RouteObject } from 'react-router-dom';

const SignIn = lazy(() => import('@pages/Auth/SignIn'));

export const SignInRoutes: RouteObject[] = [
  {
    path: 'sign-in',
    element: (
      <React.Suspense>
        <SignIn />
      </React.Suspense>
    ),
    handle: {
      label: 'Sign in',
    },
  },
];
