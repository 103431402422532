import React from 'react';

export const Transcript = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#0370F3" />
      <path
        d="M17.9892 9.58333L22.4167 14.0108V22.4167H9.58333V9.58333H17.9892ZM17.9892 7.75H9.58333C8.575 7.75 7.75 8.575 7.75 9.58333V22.4167C7.75 23.425 8.575 24.25 9.58333 24.25H22.4167C23.425 24.25 24.25 23.425 24.25 22.4167V14.0108C24.25 13.525 24.0575 13.0575 23.7092 12.7183L19.2817 8.29083C18.9425 7.9425 18.475 7.75 17.9892 7.75ZM11.4167 18.75H20.5833V20.5833H11.4167V18.75ZM11.4167 15.0833H20.5833V16.9167H11.4167V15.0833ZM11.4167 11.4167H17.8333V13.25H11.4167V11.4167Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};
