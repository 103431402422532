import React from 'react';

export const GoArrow = ({
  height,
  width,
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width || '100%'}
      height={height || '100%'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.909 4.24614C10.7122 4.41137 10.6313 4.62583 10.6313 4.84379C10.6313 5.06176 10.7122 5.27622 10.909 5.40981L14.2194 8.78129H4.74381C4.27623 8.78129 3.90006 9.16098 3.90006 9.62504C3.90006 10.0891 4.27799 10.4688 4.74381 10.4688H14.2194L10.8785 13.8097C10.5489 14.1393 10.5489 14.6731 10.8785 15.0029C11.208 15.3327 11.7419 15.3325 12.0717 15.0029L16.8529 10.2216C17.1825 9.89206 17.1825 9.35821 16.8529 9.02844L12.0717 4.24719C11.7422 3.91778 11.2079 3.91778 10.909 4.24614Z"
        fill="white"
      />
    </svg>
  );
};
